<template>
  <image-content show-navigation show-footer>
    <template #image-headline>
      <h1
        class="Headline is-size-4"
        v-t="'userRoles.careGiver'"
      />
    </template>

    <template #image>
      <figure>
        <img
          src="@/assets/images/avatar-caregiver.png"
          :alt="$t('userRoles.careGiver')"
        />
      </figure>
    </template>

    <template #image-below>
      <p>
        <strong
          class="Headline is-size-5 d-block"
          v-t="'registration.benefits'"
        />

        <ul>
          <li
            v-for="(item, index) in benefits"
            :key="index"
          >
            {{ item }}
          </li>
        </ul>
      </p>
    </template>

    <template #content>
      <div id="RegisterForm" class="Content">
        <regapp-feedback v-if="registered">
          <template #body>
            <div v-html="translateWithMymedelString(`${responseMessageKey}.${fullRegistration ? 'full' : 'basic'}`)" />
          </template>

          <template #footer>
            <br>
            <button class="ButtonLink" @click="openLogin">{{ $t('links.toLogin.label') }}</button>
          </template>
        </regapp-feedback>

        <step-list
          v-if="!registered"
          ref="steps"
          :step="step"
          :steps="steps"
          v-on:triggerPrevStep="this.prevStep()"
        />

        <Form
          v-if="!registered"
          v-slot="slotProps"
          ref="form"
          @submit="submitCaregiverRegistration"
          class="Step"
        >
          <!-- step 1 -->
          <transition :name="formTransition" mode="out-in">
            <div v-show="isStepOne">
              <strong
                class="Headline is-size-5 d-block"
                v-t="'registration.careGiver.title'"
              />
              <input-component :fieldName="fieldNames.email" :fieldLabel="emailFieldLabel" :rules="`required|email`" type="email" :errors="slotProps"/>
              <input-password-component
                :fieldName="fieldNames.password"
                :fieldLabel="passwordFieldLabel"
                :rules="`required|passwordMasterRule:@${fieldNames.email}`"
                :errors="slotProps"
              />
              <input-component
                :fieldName="fieldNames.passwordConf"
                :fieldLabel="passwordConfirmationFieldLabel"
                :rules="`required|passwordConfirm:@${fieldNames.password}`"
                type="password"
                :errors="slotProps"
              />
              <input-component :fieldName="fieldNames.firstName" :fieldLabel="firstNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>
              <input-component :fieldName="fieldNames.lastName" :fieldLabel="lastNameFieldLabel" :rules="`required|max:20`" type="text" :errors="slotProps"/>

              <input-select-component
                :fieldName="fieldNames.country"
                :fieldLabel="countryFieldLabel"
                :options="countries"
                @input="updateCountryField($event)"
              />

              <input-checkbox-component
                :fieldName="fieldNames.terms"
                :fieldLabel="$t('form.terms')"
                :errors="slotProps.errors[fieldNames.terms]"
              >
                <terms-of-use-and-privacy-policy-links />
              </input-checkbox-component>
            </div>
          </transition>
          <!-- step 2 -->
          <transition :name="formTransition" mode="out-in">
            <div v-show="isStepTwo">
              <strong
                class="Headline is-size-5 d-block"
                v-text="$t('registration.recipient.information')"
              />

            <InputDatepickerComponent
              :fieldName="fieldNames.dateOfBirth"
              :fieldLabel="recipientDateOfBirthFieldLabel"
              :validationRules="isStepTwo ? 'noToday|noFuture|max130|required|' : ''"
            />

            <input-component :fieldName="fieldNames.recipientFirstName" :fieldLabel="recipientFirstNameFieldLabel" :rules="isStepTwo ? 'required|max:20' : ''" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.recipientLastName" :fieldLabel="recipientLastNameFieldLabel" :rules="isStepTwo ? 'required|max:20' : ''" type="text" :errors="slotProps"/>
            <input-select-component
              :fieldName="fieldNames.recipientRelationship"
              :fieldLabel="recipientRelationshipFieldLabel"
              :options="careGiverOptions"
              :rules="isStepTwo ? `required` : ''"
            />
          </div>
          </transition>
          <!-- step 3 -->
          <transition :name="formTransition" mode="out-in">
          <div v-show="isStepThree">
            <strong
              class="Headline is-size-5 d-block"
              v-text="$t('device.data')"
            />
            <div class="form-checkboxes">
              <InputRadioComponent
              :fieldName="'deviceType'"
              :fieldLabel="$t('device.implant')"
              option-value="implant"
              :rules="isStepThree ? { required: { allowFalse: false } } : ''"
              @click.native="setDeviceType('implant')"
            />

            <InputRadioComponent
              :fieldName="'deviceType'"
              :fieldLabel="$t('device.audioProcessor')"
              option-value="audio-processor"
              :rules="isStepThree ? { required: { allowFalse: false } } : ''"
              class="has-lg-ml"
              @click.native="setDeviceType('audio-processor')"
            />
            </div>
            <input-select-component
              :fieldName="fieldNames.deviceModel"
              :fieldLabel="deviceSelectorFieldLabel"
              :errors="slotProps.errors[$t('device.name')]"
              :options="deviceOptions"
              :rules="isStepThree ? `required` : ''"
            />

            <input-component :fieldName="fieldNames.serialNumber" :fieldLabel="serialNumberFieldLabel" :rules="isStepThree ? `required|min_value:1|max_value:100000000` : ''" type="number" :errors="slotProps"/>

            <strong
              class="Headline is-size-5 d-block"
              v-text="`${$t('userRoles.recipient')} ${$t('address.data')}`"
            />
            <input-component :fieldName="fieldNames.street" :fieldLabel="streetFieldLabel" :rules="isStepThree ? `required|max:100` : ''" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.street1" :fieldLabel="street1FieldLabel" :rules="isStepThree ? `max:100` : ''" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.city" :fieldLabel="cityFieldLabel" :rules="isStepThree ? `required|max:50` : ''" type="text" :errors="slotProps"/>
            <input-component v-if="isCountryUs" :fieldName="fieldNames.state" :fieldLabel="stateFieldLabel" :rules="isStepThree ? `required|max:50` : ''" type="text" :errors="slotProps"/>
            <input-component :fieldName="fieldNames.zipCode" :fieldLabel="zipCodeFieldLabel" :rules="isStepThree ? `required|max:20` : ''" type="text" :errors="slotProps"/>
          </div>
        </transition>

            <button
              class="Button form-button d-block"
              :class="{ 'is-grey': !slotProps.meta.valid }"
            >
              <span v-show="!isCheckingMail" v-text="$t(nextLabel)" />
              <simple-spinner
                :show="isCheckingMail"
                classNames="is-registration"
              />
            </button>

            <button
              type="button"
              v-if="step > 0"
              class="Button form-button is-link d-block"
              v-text="$t('form.prev')"
              @click="prevStep"
            />
        </Form>
        <p>
          {{ $t('form.alreadyAccount') }}
          <button class="ButtonLink" @click="openLogin">{{ $t('form.loginNow') }}</button>
        </p>

        <validation-error-message :errors="errors" ref="validationErrorMessage" />

        <sweet-modal icon="warning" ref="modalError">
          <div v-html="$t('registration.messages.internal:error')" />
        </sweet-modal>
      </div>
    </template>
  </image-content>
</template>

<script>
import registrationSteps from '@/mixins/registrationForm.js'
import { Form } from 'vee-validate'
import ValidationErrorMessage from '../components/ValidationErrorMessage.vue'
import localeMessages from '@/mixins/localeMessages'
import TermsOfUseAndPrivacyPolicyLinks from '@/components/partials/TermsOfUseAndPrivacyPolicyLinks.vue'
import InputComponent from '@/components/partials/InputComponent.vue'
import InputCheckboxComponent from '@/components/partials/InputCheckboxComponent.vue'
import InputPasswordComponent from '@/components/partials/InputPasswordComponent.vue'
import InputSelectComponent from '@/components/partials/InputSelectComponent.vue'
import InputDatepickerComponent from '@/components/partials/InputDatepickerComponent.vue'
import InputRadioComponent from '@/components/partials/InputRadioComponent.vue'

export default {
  name: 'RegisterCaregiver',

  metaInfo () {
    return {
      title: this.$i18n.t('pages.recipientRegistration.meta.title')
    }
  },

  mixins: [ registrationSteps, localeMessages ],

  components: {
    ValidationErrorMessage,
    TermsOfUseAndPrivacyPolicyLinks,
    Form,
    InputComponent,
    InputPasswordComponent,
    InputSelectComponent,
    InputCheckboxComponent,
    InputDatepickerComponent,
    InputRadioComponent
  },

  mounted () {
    this.scrollUp()
    this.$refs.form.setFieldValue('deviceType', 'implant')
  },

  computed: {
    benefits () {
      return this.$tm('registration.careGiver.benefits')
    },

    isStepOne () {
      return this.step === 0
    },

    isStepTwo () {
      return this.step === 1
    },

    isStepThree () {
      return this.step === 2
    },

    isImplantDevice () {
      return this.deviceType === 'implant'
    },

    deviceOptions () {
      return this.isImplantDevice ? this.implantOptions : this.audioProcessorOptions
    },

    implantOptions () {
      return this.getOptionValues('dropdowns.implants')
    },

    audioProcessorOptions () {
      return this.getOptionValues('dropdowns.audioProcessors')
    },

    careGiverOptions () {
      return this.getOptionValues('dropdowns.caregiverRelation', true)
    }
  },

  watch: {
    country: function () {
      if (this.countryHasFullRegistration()) {
        this.steps = this.fullRegistrationSteps
      } else {
        this.steps = 0
      }
    },
    step: function () {
      if (this.step === 0) {
        this.$refs.form.setFieldValue(this.fieldNames.passwordConf, '')
      }
    }
  },

  data () {
    return {
      errors: {},
      steps: 3,
      step: 0,
      fullRegistrationSteps: 3,
      country: null,
      confirmations: {
        terms: false
      },
      registered: false,
      hasError: false,
      isCheckingMail: false,
      fullRegistration: true,
      responseMessageKey: '',
      formTransition: 'form-step-left',
      isFormButtonDisabled: true,
      deviceType: 'implant',
      fieldNames: {
        email: 'email',
        password: 'password',
        passwordConf: 'passwordConf',
        firstName: 'firstName',
        lastName: 'lastName',
        country: 'country',
        terms: 'terms',
        dateOfBirth: 'dateOfBirth',
        recipientFirstName: 'recipientFirstName',
        recipientLastName: 'recipientLastName',
        recipientRelationship: 'recipientRelationship',
        deviceModel: 'deviceModel',
        serialNumber: 'serialNumber',
        street: 'street',
        street1: 'street1',
        city: 'city',
        state: 'state',
        zipCode: 'zipCode'
      }
    }
  },

  methods: {
    async submitCaregiverRegistration (values) {
      // if there are more steps go to next page
      let tempValues = Object.assign({}, this.$refs.form.getValues())
      if (this.step + 1 < this.steps) {
        this.$refs.form.resetForm({ errors: {}, values: tempValues })
        return this.nextStep()
      }

      let success = false
      if (this.countryHasFullRegistration()) {
        this.fullRegistration = true
        success = await this.createUserProfileAndUpgrade(this.getFullRequestObject(values))
      } else {
        this.fullRegistration = false
        success = await this.createUserProfile(this.getBasicRequestObject(values))
      }

      if (success) {
        this.responseMessageKey = `registration.messages.success`
        this.registered = true
        this.scrollUp()
      }
    },
    setDeviceType (devicetype) {
      if (this.deviceType === devicetype) {
        return
      }
      this.deviceType = devicetype
      this.$refs.form.setFieldValue(this.fieldNames.deviceModel, '')
    },

    getFullRequestObject (values) {
      let basicFields = this.getBasicRequestObject(values)
      return Object.assign(basicFields, {
        registrationType: 'full',
        deviceType: values['deviceType'],
        device: values[this.fieldNames.deviceModel]?.trim(),
        deviceSerialNumber: values[this.fieldNames.serialNumber],
        street: values[this.fieldNames.street]?.trim(),
        street1: values[this.fieldNames.street1]?.trim(),
        city: values[this.fieldNames.city]?.trim(),
        state: values[this.fieldNames.state]?.trim(),
        zipCode: values[this.fieldNames.zipCode]?.trim(),
        relationship: values[this.fieldNames.recipientRelationship].code,
        recipientDateOfBirth: values[this.fieldNames.dateOfBirth],
        recipientFirstName: values[this.fieldNames.recipientFirstName]?.trim(),
        recipientLastName: values[this.fieldNames.recipientLastName]?.trim()
      })
    },
    getBasicRequestObject (values) {
      return {
        registrationType: 'basic',
        email: values[this.fieldNames.email]?.trim(),
        firstName: values[this.fieldNames.firstName]?.trim(),
        lastName: values[this.fieldNames.lastName]?.trim(),
        password: values[this.fieldNames.password],
        userType: 'Caregiver',
        language: this.$i18n.locale ? this.$i18n.locale : 'en',
        country: values[this.fieldNames.country].code,
        agreedToTermsAndConditions: values[this.fieldNames.terms],
        dateOfRegistration: new Date(),
        signupUrl: this.getSignupUrl()
      }
    },

    updateCountryField (value) {
      this.country = value
    }
  }
}
</script>
