<template>
  <div class="form-input">
    <label class="form-radios form-input-label">
      <Field
        :name="fieldName"
        :label="fieldLabel"
        :rules="rules"
        type="radio"
        :value="optionValue"
        class="form-radiobox-field"/>
      <span class="form-radiobox-value" v-text="fieldLabel" />
    </label>
  </div>
</template>
<script>

import { Field } from 'vee-validate'
export default {
  name: 'InputRadioComponent',

  components: { Field },

  props: {
    rules: {
      type: Object,
      default: () => { return { required: { allowFalse: false } } }
    },

    fieldLabel: {
      type: String,
      default: 'Input'
    },

    fieldName: {
      type: String,
      required: true
    },

    optionValue: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import '../../styles/media-queries';
  .form-radios {
    display: flex;
    flex-direction: column;

    @include media('>=sm') {
      flex-direction: row;
    }
  }
</style>
