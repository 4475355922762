<template>
<div>
  <Field
    :name="fieldName"
    :label="fieldLabel"
    tag="div"
    :rules="validationRules"
    class="form-input"
    v-slot="{ field, errors }"
  >
    <label
      :class="{ 'form-input-label': !this.isDisabled,
                'inactive-form-input-label': this.isDisabled,
                'is-error': errors.length > 0 }"
    >
      <small :class="{ 'd-block': !this.isDisabled,
                       'form-input-title': !this.isDisabled,
                       'inactive-form-input-title': this.isDisabled,
                       'is-error': errors.length > 0 }" v-text="fieldLabel" />

      <input type="date"
        v-if="!this.isDisabled"
        v-bind="field"
        class="form-input-field d-block"
        @input="updateInput"
        @blur="blur"
        :disabled="isDisabled"
      />
      <div v-else class="inactive-form-value">{{ field.value }}</div>

      <p class="form-input-error" v-show="errors.length" v-text="errors[0]" />
    </label>
  </Field>
</div>
</template>
<script>

import formFields from '@/mixins/formFields'
import { Field } from 'vee-validate'
export default {
  name: 'InputDatepickerComponent',

  mixins: [ formFields ],

  components: { Field },

  props: {
    isEdit: {
      type: Boolean,
      default: true
    },
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },

    fieldLabel: {
      type: String,
      default: 'Input'
    },

    fieldName: {
      type: String,
      required: true
    },

    validationRules: {
      type: String,
      default: 'noFuture'
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    valueOrPlaceholder () {
      return this.value ? this.value : this.placeholder
    }
  },

  methods: {
    blur () {
      this.$emit('blur', '')
    }
  }
}
</script>
